import { defineComponent, type Directive, type PropType } from "vue";
import domPurify from "dompurify";

export interface HtmlContent {
  body: string;
  style?: Record<string, string>;
}

declare module "blocks" {
  export interface ComponentBlocks {
    "html-block": HtmlContent;
  }
}

export default defineComponent({
  name: "HtmlBlock",
  props: {
    content: { type: Object as PropType<HtmlContent>, required: true },
  },
  render() {
    const html: Directive = {
      created: (el: HTMLElement) => {
        // el.parentElement.innerHTML = domPurify.sanitize(this.content.body);
        el.innerHTML = domPurify.sanitize(this.content.body);
      },
    };

    return withDirectives(
      h("div", {
        style: this.content.style,
      }),
      [[html]]
    );
  },
});
