import {
  type Component,
  type PropType,
  defineComponent,
  resolveDirective,
} from "vue";
import LayoutStandard from "./EventCard.vue";
import LayoutImage from "./EventCardImage.vue";
import type { EventCardSource, EventCardImageSource } from "./types";

type Layouts = {
  standard: EventCardSource;
  image: EventCardImageSource;
};

export type EventContentLayout = keyof Layouts;
export type EventSourceType<L extends EventContentLayout> = Layouts[L];

const layouts: { [K in EventContentLayout]: Component } = {
  standard: LayoutStandard,
  image: LayoutImage,
};

export interface EventContent<
  T extends EventContentLayout = EventContentLayout
> {
  layout?: T;
  source: string | EventSourceType<T>;
}

declare module "blocks" {
  export interface ComponentBlocks {
    "event-block": EventContent;
  }
}

export default defineComponent({
  name: "EventBlock",
  props: {
    content: {
      type: Object as PropType<EventContent>,
      required: true,
    },
  },
  setup(props) {
    const component = computed(
      () => layouts[props.content.layout || "standard"]
    );
    return {
      component,
    };
  },
  render() {
    const node = h(this.component, {
      source: this.content.source,
    });

    const editable = resolveDirective("editable");

    return withDirectives(node, [[editable, this.content]]);
  },
});
