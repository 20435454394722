import { useLocalStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import config from "#sales";
import type { Cart, CartItem } from "../../types";
import { resolveCurrency } from "../utils";
import { sum } from "lodash";

export const useCartStore = defineStore("cart", () => {
  const fetching = ref(false);
  const checkingOut = ref(false);
  const hydrated = ref(false);
  const updating = reactive<Record<string, boolean>>({});

  const cart = useLocalStorage<Cart>("cart", {
    subtotal: 0,
    total: 0,
    items: [],
    currency: resolveCurrency(config.defaultCurrency),
  });

  const loading = computed(() => {
    if (fetching.value) return fetching.value;
    if (checkingOut.value) return checkingOut.value;
    return Object.values(updating).some((x) => x === true);
  });
  const items = computed(() =>
    cart.value.items.map((item: CartItem) => ({
      // @ts-ignore
      options: [],
      ...item,
    }))
  );
  // const conditions =  computed(() => cart.value.conditions)
  const size = computed(() => cart.value.items.length);
  const isEmpty = computed(() => cart.value.items.length == 0);
  const currency = computed(() => cart.value.currency);
  const itemsCount = computed(() =>
    sum(cart.value.items.map((item) => item.quantity))
  );
  const subtotal = computed(() => cart.value.subtotal);

  const apply = (data?: Cart) => {
    // only apply if cart exists
    if (data?.id) {
      cart.value.id = data.id;
      cart.value.subtotal = data.subtotal;
      cart.value.total = data.total;
      cart.value.items = data.items;
      cart.value.conditions = data.conditions;
      cart.value.currency = data.currency;
    }
  };

  const clearData = () => {
    cart.value.subtotal = 0;
    cart.value.total = 0;
    cart.value.items = [];
    cart.value.conditions = [];
  };

  return {
    fetching,
    updating,
    checkingOut,
    loading,
    hydrated,
    items,
    size,
    isEmpty,
    currency,
    itemsCount,
    cart,
    subtotal,
    apply,
    clearData,
  };
});
