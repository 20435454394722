import type { CountryData, CountrySlug, Region } from "country-region-data";

export const filterCountries = (
  countries: CountryData[],
  priorityCountries: CountrySlug[],
  whitelist: CountrySlug[],
  blacklist: CountrySlug[]
) => {
  const countriesListedFirst: CountryData[] = [];
  let filteredCountries: CountryData[];

  if (whitelist.length > 0) {
    filteredCountries = countries.filter(
      ([, countrySlug]) => whitelist.indexOf(countrySlug) > -1
    );
  } else if (blacklist.length > 0) {
    filteredCountries = countries.filter(
      ([, countrySlug]) => blacklist.indexOf(countrySlug) === -1
    );
  } else {
    filteredCountries = countries;
  }

  if (priorityCountries.length > 0) {
    // ensure the countries are added in the order in which they are specified by the user
    priorityCountries.forEach((slug) => {
      const result = filteredCountries.find(
        ([, countrySlug]) => countrySlug === slug
      );
      if (result) {
        countriesListedFirst.push(result);
      }
    });

    filteredCountries = filteredCountries.filter(
      ([, countrySlug]) => priorityCountries.indexOf(countrySlug) === -1
    );
  }

  return countriesListedFirst.length
    ? [...countriesListedFirst, ...filteredCountries]
    : filteredCountries;
};

export const filterRegions = (
  regions: Region[],
  whitelistObject: string[],
  blacklistObject: string[],
  useName: boolean
): Region[] => {
  if (whitelistObject.length > 0) {
    regions = regions.filter(([name, shortCode]) => {
      return whitelistObject.includes(useName ? name : shortCode);
    });
  }
  if (blacklistObject.length > 0) {
    regions = regions.filter(([name, shortCode]) => {
      return !blacklistObject.includes(useName ? name : shortCode);
    });
  }

  return regions;
};

export const isString = (value: any): value is string =>
  typeof value == "string";
