import { createHooks } from "hookable";
import { isString, set } from "lodash";
import { storeToRefs } from "pinia";
import type {
  Cart,
  CartCheckoutInput,
  CartCheckoutResult,
  CartItem,
  CartItemInput,
  CartItemUpdateOptions,
} from "../../types";
import {
  addToCartMutation,
  cartQuery,
  checkoutCartMutation,
  removeCartItemMutation,
  updateCartItemMutation,
} from "../queries/cart";
import { useCartStore } from "../stores/cart";
import { client } from "../utils";

export const useCart = () => {
  const store = useCartStore();
  const storeRefs = storeToRefs(store);
  const bus = createHooks();

  const onError = (cb: { (error: any): void }) => bus.hook("error", cb);

  const load = async (upsert?: boolean) => {
    store.fetching = true;

    const result = await client()
      .query<{ cart: Cart }>({
        query: cartQuery,
        variables: {
          upsert,
          cart: store.cart.id,
        },
      })
      .catch((error) => {
        bus.callHook("error", error);
      });

    store.fetching = false;

    if (result?.data?.cart) {
      store.apply(result.data.cart);
      store.hydrated = true;
      return store.cart;
    }
  };

  const loadOnce = async (upsert?: boolean) => {
    if (!store.hydrated) {
      await load(upsert);
    }
  };

  const add = async (item: CartItemInput | string) => {
    set(store.updating, "add", true);
    const result = await client()
      .mutate<{ cartAddItem: { item: CartItem; cart: Cart } }>({
        mutation: addToCartMutation,
        variables: {
          upsert: true,
          cart: store.cart.id,
          data: isString(item) ? { gid: item } : item,
        },
      })
      .catch((error) => {
        bus.callHook("error", error);
      });

    if (result?.data?.cartAddItem?.cart) {
      store.apply(result.data.cartAddItem.cart);
      set(store.updating, "add", false);
      return result.data.cartAddItem.item;
    }
  };

  const update = async (item: string, data: CartItemUpdateOptions) => {
    set(store.updating, `update:${item}`, true);
    const result = await client()
      .mutate<{ cartUpdateItem: { item: CartItem; cart: Cart } }>({
        mutation: updateCartItemMutation,
        variables: {
          cart: store.cart.id,
          where: { id: item },
          data,
        },
      })
      .catch((error) => {
        bus.callHook("error", error);
      });

    if (result?.data?.cartUpdateItem?.cart) {
      store.apply(result.data.cartUpdateItem.cart);
      set(store.updating, `update:${item}`, false);
      return result.data.cartUpdateItem.item;
    }
  };

  const remove = async (item: string) => {
    set(store.updating, `remove:${item}`, true);

    const result = await client()
      .mutate<{ removeFromCart: Cart }>({
        mutation: removeCartItemMutation,
        variables: {
          cart: store.cart.id,
          where: { id: item },
        },
      })
      .catch((error) => {
        bus.callHook("error", error);
      });

    if (result?.data?.removeFromCart) {
      store.apply(result.data.removeFromCart);
      set(store.updating, `remove:${item}`, false);
    }

    return store.cart;
  };

  const clear = async () => {
    await client()
      .mutate<{ cartClear: Cart }>({
        mutation: removeCartItemMutation,
        variables: {
          cart: store.cart.id,
        },
      })
      .then(() => {
        store.clearData();
      })
      .catch((error) => {
        bus.callHook("error", error);
      });
    return store.cart;
  };

  const isInCart = (gid: string) => {
    const gids = store.items.map((item) => item.gid);
    return gids.includes(gid);
  };

  const checkout = async (data?: CartCheckoutInput) => {
    store.checkingOut = true;

    const result = await client()
      .mutate<{ cartCheckout: CartCheckoutResult }>({
        mutation: checkoutCartMutation,
        variables: {
          cart: store.cart.id,
          data: {
            cancel_url: withoutQuery(window.location.href, "checkout"),
            order_notes: data?.orderNotes,
          },
        },
      })
      .catch((error) => {
        bus.callHook("error", error);
      });

    store.checkingOut = false;

    return result?.data?.cartCheckout;
  };

  const toCheckout = () => {
    const router = useRouter();
    router.push({ name: "cart", query: { checkout: "true" } });
  };

  return {
    ...storeRefs,
    load,
    loadOnce,
    update,
    add,
    remove,
    clear,
    isInCart,
    checkout,
    toCheckout,
    onError,
  };
};
