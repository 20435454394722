import { defineComponent } from "vue";
import defu from "defu";
import { type MediaLike, MediaType } from "~~/models/media";
import ImageComponent from "./Image.block.vue";
import VideoComponent from "./Video.block.vue";
import type { Link } from "~~/utils/links";
import type { ComponentBlocks } from "blocks";
import type { DeviceSizable } from ".";
import { useResizable } from "./utils";

export interface MediaContent {
  link?: Link;
  videoOptions?: Omit<ComponentBlocks["video-block"], "src">;
  imageOptions?: Omit<ComponentBlocks["image-block"], "src">;
  media?: MediaLike | DeviceSizable<MediaLike>;
}

export default defineComponent({
  name: "MediaBlock",
  props: {
    content: { type: Object as PropType<MediaContent>, required: true },
  },
  setup(props) {
    const media = useResizable(props.content.media);

    return {
      media,
    };
  },
  computed: {
    isImage(): boolean {
      return this.media?.type === MediaType.IMAGE;
    },
    isVideo(): boolean {
      return this.media?.type === MediaType.VIDEO;
    },
  },
  render() {
    if (this.media) {
      const options = {
        alt: this.media.alt || this.media.description,
        height: this.media.height,
        width: this.media.width,
        link: this.content.link,
        src: this.media.src,
      };

      if (this.isImage) {
        return h(ImageComponent, {
          content: defu(options, this.content.imageOptions),
        });
      }
      if (this.isVideo) {
        return h(VideoComponent, {
          content: defu(options, this.content.videoOptions),
        });
      }
    }

    return h("div");
  },
});
