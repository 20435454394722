import { defineComponent, type PropType, resolveDirective } from "vue";
import type { ComponentBlock } from "../types";
import LayoutBasic from "./LayoutBasic.vue";
import LayoutL5 from "./LayoutL5.vue";
import LayoutWaterfall from "./LayoutWaterfall.vue";
import LayoutCollection from "./LayoutCollection.vue";

const layouts = {
  basic: LayoutBasic,
  l5: LayoutL5,
  waterfall: LayoutWaterfall,
  collection: LayoutCollection,
};

export type GridLayout = keyof typeof layouts;

export interface Grid {
  layout?: GridLayout;
  columns: ComponentBlock[];
  layoutOptions?: Record<string, any>;
}

declare module "blocks" {
  export interface ComponentBlocks {
    "grid-block": Grid;
  }
}

export default defineComponent({
  name: "GridBlock",
  props: {
    content: {
      type: Object as PropType<Grid>,
      required: true,
    },
  },
  setup(props) {
    const component = computed(() => layouts[props.content.layout || "basic"]);
    return {
      component,
    };
  },
  render() {
    const node = h(this.component, {
      columns: this.content.columns,
      options: this.content.layoutOptions,
    });

    const editable = resolveDirective("editable");

    return withDirectives(node, [[editable, this.content]]);
  },
});
