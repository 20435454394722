import Notifications from "@kyvg/vue3-notification";
import VPhoneInput from "~~/components/inputs/VPhoneInput";
import VDynamicForm from "~~/components/inputs/VDynamicForm";
import VCountrySelect from "~~/components/inputs/VCountrySelect";

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.use(Notifications);
  vueApp.use(VPhoneInput);
  vueApp.use(VDynamicForm);
  vueApp.use(VCountrySelect);
});
