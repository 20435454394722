export const setParam = (
  param: string,
  value: string | number | null | undefined
) => {
  const params = new URLSearchParams(window.location.search);
  params.set(param, String(value));
  // window.location.search = params.toString();
  // @ts-expect-error We need to use null here
  window.history.replaceState(null, null, "?" + params.toString());
};

export const getParam = <T extends string>(
  param: string
): T | null | undefined => {
  const params = new URLSearchParams(window.location.search);
  return params.get(param) as T | null | undefined;
};

export const withoutQuery = (url: string, ...params: string[]) => {
  const r = new URL(url);
  for (const p of params) {
    r.searchParams.delete(p);
  }
  return r.href;
};
