import gql from "graphql-tag";

const addressQueryFields = `
  id
  company
  phone
  first_name
  last_name
  street
  street_extra
  city
  province_code
  postal_code
  country_code
  description
  latitude
  longitude
  is_public
`;

const shippingMethodQueryFields = `
  id
  name
  description
  handle
  price
  type
  estimated_min_days
  estimated_max_days
`;

const checkoutQueryFields = `
  test
  token
  first_name
  last_name
  email
  phone
  completed
  mode
  requires_shipping
  allow_promotion_codes
  collect_phone_number
  submit_type
  web_url
  success_url
  cancel_url
  shipping_line
  discount_code
  error_messages
  shipping_address{ ${addressQueryFields} }
  billing_address{ ${addressQueryFields} }
  currency{ symbol code }
  lines {
    id name quantity total unit_price item_type
    image{ id type src thumb alt height width }
    adjustable_quantity{
      enabled maximum minimum
    }
  }
  shippingMethod{ ${shippingMethodQueryFields} }
  shippingMethods{ ${shippingMethodQueryFields} }
  paymentOptions {
    name
    handle
    description
    express
    icon
    component
    data
  }
  order{
    reference
    email
    currency{ symbol code }
    total
  }
  total_discounts
  total_shipping
  total_tax
  subtotal_price
  total_price
`;

export const createCheckoutMutation = gql`
  mutation ($data: CheckoutCreateInput!) {
    checkoutCreate(data: $data) {
      ${checkoutQueryFields}
    }
  }
`;

export const updateCheckoutMutation = gql`
  mutation ($token: String!, $data: CheckoutUpdateInput!) {
    checkoutUpdate(token: $token, data: $data) {
      ${checkoutQueryFields}
    }
  }
`;

export const checkoutSelectShippingMutation = gql`
mutation ($token: String!, $line: String!) {
  checkoutSelectShipping(token: $token, line: $line) {
      ${checkoutQueryFields}
    }
  }
`;

export const checkoutCompleteMutation = gql`
mutation ($token: String!, $data: CheckoutCompleteInput) {
  checkoutComplete(token: $token, data: $data) {
      ${checkoutQueryFields}
    }
  }
`;

export const checkoutQuery = gql`
  query ($token: String!) {
    checkout(token: $token) {
      ${checkoutQueryFields}
    }
  }
`;

export const checkoutShippingMethodsQuery = gql`
  query ($token: String!) {
    checkoutShippingMethods(token: $token) {
      ${shippingMethodQueryFields}
    }
  }
`;
