import { set } from "lodash";
import { defineStore } from "pinia";
import config from "#sales";
import {
  type Checkout,
  CheckoutSubmitType,
  PaymentType,
} from "../../types/checkout";
import { resolveCurrency } from "../utils";

// Add more groups as needed
type ErrorGroup = "stripe";
type ErrorMessageGroup<G extends ErrorGroup = ErrorGroup> = {
  [group in G]?: string[];
};

export const useCheckoutStore = (token?: string) => {
  let key = "checkout";
  if (token) {
    key = `checkout:${token}`;
    if (token.includes(":")) {
      const [_group, tkn] = token.split(":");
      token = tkn;
    }
  }

  const store = defineStore(key, () => {
    const checkout = reactive<Checkout>({
      token: "",
      completed: false,
      mode: PaymentType.PAYMENT,
      requires_shipping: false,
      allow_promotion_codes: true,
      collect_phone_number: false,
      submit_type: CheckoutSubmitType.AUTO,
      currency: resolveCurrency(config.defaultCurrency),
      lines: [],
      total_discounts: 0,
      total_shipping: 0,
      total_tax: 0,
      subtotal_price: 0,
      total_price: 0,
      shippingMethods: [],
      paymentOptions: [],
      error_messages: [],
    });

    const fetching = ref(false);
    const creating = ref(false);
    const updating = ref(false);
    const submitting = ref(false);
    const disableAdjustments = ref(false);
    const extraErrorMessages = ref<ErrorMessageGroup>({});
    const shippingAsBilling = ref(true);
    const originalShippingAsBilling = ref<boolean | null>(null);
    const originalDiscountCode = ref<boolean | null>(null);

    const itemsCount = computed(() => checkout.lines.length);
    const loading = computed(
      () =>
        fetching.value || creating.value || updating.value || submitting.value
    );
    const isEmpty = computed(() => checkout.lines.length == 0);
    const errorMessages = computed((): string[] => {
      const messages = checkout.error_messages;
      return messages.concat(Object.values(extraErrorMessages.value).flat());
    });
    const hasErrors = computed(() => !!errorMessages.value.length);

    const fill = (data: Checkout) => {
      Object.entries(data).forEach(([key, value]) => {
        if (key == "paymentOptions") {
          value = (value as Checkout["paymentOptions"]).map((option) => ({
            ...option,
            data: option.data ? parseJsonString(option.data) : option.data,
          }));
        } else if (key == "discount_code") {
          originalDiscountCode.value = value;
        }
        set(checkout, key, value);
      });

      if (checkout.shipping_address && checkout.billing_address) {
        originalShippingAsBilling.value = shippingAsBilling.value =
          checkout.shipping_address.id == checkout.billing_address.id;
      } else {
        originalShippingAsBilling.value = null;
      }
    };

    return {
      checkout,
      itemsCount,
      fetching,
      creating,
      updating,
      submitting,
      loading,
      isEmpty,
      disableAdjustments,
      fill,
      errorMessages,
      extraErrorMessages,
      hasErrors,
      shippingAsBilling,
      originalShippingAsBilling,
      originalDiscountCode,
    };
  });

  return store();
};
