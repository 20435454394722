import { defineNuxtPlugin } from "#app";
import type { ApolloClient } from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import { ApolloClients, provideApolloClients } from "@vue/apollo-composable";
import { createApolloProvider } from "@vue/apollo-option";
import createDefaultClient from "./default-client";
import createSalesClient from "./sales-client";
import createContentClient from "./content-client";
import type { SetClientOptions } from "./base";

export default defineNuxtPlugin((nuxtApp) => {
  const errorLink = onError((_error) => {
    // const statusCode = get(error, "networkError.statusCode");
    // const statusMessage = get(error, "graphQLErrors[0].message", "");
    // Log network error
    // notify({
    //   group: "network",
    //   title: "Network Error",
    //   text: "Error communicating with server",
    // });
    // if (
    //   statusCode === 401 ||
    //   statusMessage.toLocaleLowerCase().includes("unauthenticated") ||
    //   statusMessage == "User not Authenticated"
    // ) {
    //   $auth?.refreshTokens();
    // }
  });

  const config = useRuntimeConfig();
  const clients: Record<string, ApolloClient<any>> = {};
  const clientsSymbols: Record<symbol | string, ApolloClient<any>> = {};

  const options = {
    errorLink,
    nuxtApp: nuxtApp as any,
    config: config.public,
    setClient({ key, symbol, client }: SetClientOptions) {
      clients[key] = client;
      if (symbol) {
        clientsSymbols[symbol.toString()] = client;
        clientsSymbols[symbol] = client;
      }
    },
  };

  createDefaultClient(options);
  createSalesClient(options);
  createContentClient(options);

  const apolloProvider = createApolloProvider({
    defaultClient: clients.default,
  });

  nuxtApp.vueApp.provide(ApolloClients, clients);

  provideApolloClients({
    ...clientsSymbols,
    ...clients,
  });

  nuxtApp.vueApp.use(apolloProvider);
});
