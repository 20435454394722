import { DefaultApolloClient } from "@vue/apollo-composable";
import defaultClientCreator from "./base";

export default defaultClientCreator(() => ({
  key: "default",
  symbol: DefaultApolloClient,
  httpOptions: {
    headers: {
      "X-Access": "orie-buyer",
    },
  },
}));
