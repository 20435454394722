import type { Plugin } from "vue";
import VDynamicForm from "./components/VDynamicForm";
import VInputGroup from "./components/VInputGroup";

const plugin: Plugin = {
  install: function (app) {
    app.component("VDynamicForm", VDynamicForm);
    app.component("VInputGroup", VInputGroup);
  },
};

export default plugin;
export { VDynamicForm, VInputGroup };
