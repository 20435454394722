import type { Plugin } from "vue";
import VPhoneInput from "./components/VPhoneInput";

const plugin: Plugin = {
  install: function (app) {
    app.component("VPhoneInput", VPhoneInput);
  },
};

export default plugin;
export { VPhoneInput };
