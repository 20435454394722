import type { Coordinate } from "ol/coordinate";
import type { Currency } from "./commerce";
import type { Entity } from "~/components/elements/event-map/types";

// export interface Checkout {
//   token: string;
//   subtotal: number;
//   total: number;
//   lines: CheckoutLine[];
//   currency: Currency;
// }

// export type CheckoutLine = {
//   id: string | number;
//   name: string;
//   // price: number;
//   quantity: number;
// };
export const addressInputFields: (keyof AddressInput)[] = [
  // 'company',
  // 'description',
  "first_name",
  "last_name",
  "phone",
  "street",
  "street_extra",
  "city",
  "postal_code",
  "province_code",
  "country_code",
  "latitude",
  "longitude",
  "is_public",
];

export interface Checkout {
  test?: boolean;
  token: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
  completed: boolean;
  mode: PaymentType;
  requires_shipping: boolean;
  allow_promotion_codes: boolean;
  collect_phone_number: boolean;
  web_url?: string;
  success_url?: string;
  cancel_url?: string;
  submit_type: CheckoutSubmitType;
  shipping_address?: Address;
  billing_address?: Address;
  shipping_line?: string;
  discount_code?: string;
  currency: Currency;
  lines: CheckoutLine[];
  total_discounts: number;
  total_shipping: number;
  total_tax: number;
  subtotal_price: number;
  total_price: number;
  shippingMethod?: ShippingMethod;
  shippingMethods: ShippingMethod[];
  paymentOptions: CheckoutPaymentOption[];
  error_messages: string[];
  order?: CheckoutOrder;
  seats?: string[];
  event_map?: EventMap;
}

export type EventMap = {
  viewport?: {
    zoom: number;
    center: Coordinate;
  };
  entities?: Entity[];
  seats: string[];
  available_seats: string[];
};

// export enum CheckoutStatus {
//   OPEN = 'OPEN',
//   READY = 'READY',
//   COMPLETE = 'COMPLETE',
//   EXPIRED = 'EXPIRED',
// }

export enum PaymentType {
  PAYMENT = "PAYMENT",
  SETUP = "SETUP",
  SUBSCRIPTION = "SUBSCRIPTION",
}

export type PaymentMethodType = "in:card";

export enum OrderKind {
  PRODUCT = "PRODUCT",
  SERVICE = "SERVICE",
  EVENT = "EVENT",
  MIXED = "MIXED",
  OTHER = "OTHER",
}

export enum CheckoutSubmitType {
  AUTO = "AUTO",
  PAY = "PAY",
  BOOK = "BOOK",
  DONATE = "DONATE",
}

export type CheckoutLine = {
  id: string | number;
  name: string;
  price: number;
  quantity: number;
  total: number;
  unit_price: number;
  item_type: string;
  image?: PublicMedia;
  // product: Product
  adjustable_quantity?: AdjustableQuantity;
};

export type CartItemInput = {
  item: string;
  quantity: number;
};

export type ShippingMethod = {
  id: string;
  name: string;
  description?: string;
  handle: string;
  price: number;
  type: ShippingMethodType;
  estimated_min_days?: number;
  estimated_max_days?: number;
};

export type CheckoutPaymentOption = {
  name: string;
  description?: string;
  handle: string;
  express?: boolean;
  icon?: string;
  component?: string;
  data?: string;
};

export type CheckoutOrder = {
  reference: string;
  email?: string;
  currency: Currency;
  total: number;
};

export enum ShippingMethodType {
  DELIVERY = "DELIVERY",
  PICKUP_DELIVERY = "PICKUP_DELIVERY",
  DROPOFF_DELIVERY = "DROPOFF_DELIVERY",
  LOCAL_PICKUP = "LOCAL_PICKUP",
  LOCAL_DELIVERY = "LOCAL_DELIVERY",
}

type AdjustableQuantity = {
  enabled?: boolean;
  maximum?: number;
  minimum?: number;
};

export type CheckoutCreateInput = {
  kind?: OrderKind;
  mode?: PaymentType;
  payment_method_types?: PaymentMethodType[];
  success_url?: string;
  cancel_url?: string;
  client_reference?: string;
  cart?: string;
  use_cart?: boolean;
  customer?: string;
  email?: string;
  phone?: string;
  expires_at?: string;
  allow_promotion_codes?: boolean;
  create_customer?: boolean;
  collect_phone_number?: boolean;
  must_authenticate?: boolean;
  lines?: CheckoutLineItemInput[];
  discounts?: string[];
  seats?: string[];
  submit_type?: CheckoutSubmitType;
  shipping_address?: AddressInput;
  billing_address?: AddressInput;
  billing_as_shipping?: boolean;
  shipping_as_billing?: boolean;
};

export type CheckoutUpdateInput = {
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
  shipping_address?: CheckoutHasShippingAddressUpdateInput;
  billing_address?: CheckoutHasBillingAddressUpdateInput;
  lines?: CheckoutHasItemsUpdateInput;
  discount?: CheckoutDiscountCodeUpdateInput;
  seats?: string[];
};

export type CheckoutCompleteInput = {
  payment_metho?: string;
  options?: Record<string, any>;
};

export interface AddressInput {
  company?: string;
  description?: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  street: string;
  street_extra?: string;
  city: string;
  postal_code?: string;
  province_code?: string;
  country_code: string;
  latitude?: number;
  longitude?: number;
  is_public?: boolean;
}

export interface Address extends AddressInput {
  id: string;
}

type CheckoutHasShippingAddressUpdateInput = {
  set?: AddressInput;
  use_billing?: boolean;
  delete?: boolean;
  use?: string;
};

type CheckoutHasBillingAddressUpdateInput = {
  set?: AddressInput;
  use_shipping?: boolean;
  delete?: boolean;
  use?: string;
};

type CheckoutHasItemsUpdateInput = {
  set?: CheckoutLineItemInput[];
  add?: CheckoutLineItemInput[];
  update?: CheckoutLineItemUpdateInput[];
  delete?: string[];
};

type CheckoutLineItemInput = {
  id: string;
  quantity?: number;
  properties?: Record<string, any>;
};

type CheckoutLineItemUpdateInput = {
  id: string;
  quantity: number;
};

type CheckoutDiscountCodeUpdateInput = {
  set?: string[];
  apply?: string[];
  delete?: string[];
  clear?: boolean;
};

export type PublicMedia = {
  id: string;
  fqfn: string;
  name?: string;
  description?: string;
  type: string;
  src: string;
  srcset?: string;
  thumb?: string;
  alt?: string;
  height?: number | string;
  width?: number | string;
  aspectRatio?: number | string;
};
