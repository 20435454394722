import { setContext } from "@apollo/client/link/context";
import defaultClientCreator from "./base";

export const SalesApolloClient = Symbol("sales-apollo-client");

export default defaultClientCreator(({ config }) => {
  const setCurrency = setContext(async (_, { headers }) => {
    const { currency } = useCurrency();

    return {
      headers: {
        ...headers,
        "X-Currency": currency.value.code,
      },
    };
  });

  return {
    key: "sales",
    symbol: SalesApolloClient,
    httpOptions: {
      headers: {
        "X-Access": "sales-channel",
        "X-ClientAccessToken": config.server.salesApiAccessToken,
      },
    },
    links: [setCurrency],
  };
});
