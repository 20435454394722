export default defineNuxtPlugin(() => {
  const cart = useCart();
  const checkout = useCheckout();

  return {
    provide: {
      cart,
      checkout,
    },
  };
});
