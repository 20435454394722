import { defineRule } from "vee-validate";
import {
  required,
  email,
  min,
  max,
  min_value,
  max_value,
} from "@vee-validate/rules";

export default defineNuxtPlugin(() => {
  defineRule("required", required);
  defineRule("email", email);
  defineRule("min", min);
  defineRule("max", max);
  defineRule("min_value", min_value);
  defineRule("max_value", max_value);
});
