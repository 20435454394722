<template>
  <div class="format dark:format-invert h-100vh flex items-center">
    <div class="max-w-3xl px-4 mx-auto lg:px-4">
      <h1 class="text-40 opacity-20">
        {{ error.statusCode }}
      </h1>
      <h2 class="text-4">{{ error.statusMessage || error.message }}</h2>
    </div>
  </div>
</template>

<script lang="ts">
import type { NuxtError } from "nuxt/app";
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  props: {
    error: { type: Object as PropType<NuxtError>, required: true },
  },
  methods: {
    redirect() {
      this.$emit("redirect");
    },
  },
});
</script>
