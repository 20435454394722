import { pick } from "lodash";
import { createHooks } from "hookable";
import { useCheckoutStore } from "../stores/checkout";
import type {
  AddressInput,
  Checkout,
  CheckoutCompleteInput,
  CheckoutCreateInput,
  CheckoutUpdateInput,
  ShippingMethod,
} from "../../types";
import {
  checkoutCompleteMutation,
  checkoutQuery,
  checkoutSelectShippingMutation,
  checkoutShippingMethodsQuery,
  createCheckoutMutation,
  updateCheckoutMutation,
} from "../queries/checkout";
import { client } from "../utils";

export const useCheckout = (token?: string) => {
  const { fill, checkout, ...store } = useCheckoutStore(token);
  const bus = createHooks();

  const onError = (cb: { (error: any): void }) => bus.hook("error", cb);

  const load = async (token: string): Promise<boolean> => {
    store.fetching = true;

    const result = await client()
      .query<{ checkout: Checkout }>({
        query: checkoutQuery,
        variables: {
          token,
        },
      })
      .catch((error) => {
        bus.callHook("error", error);
      })
      .finally(() => {
        store.fetching = false;
      });

    if (result) {
      fill(result.data.checkout);
      return true;
    }

    return false;
  };

  const create = async (data: CheckoutCreateInput = {}): Promise<boolean> => {
    store.creating = true;
    const result = await client()
      .mutate<{ checkoutCreate: Checkout }>({
        mutation: createCheckoutMutation,
        variables: {
          token: checkout.token,
          data,
        },
      })
      .catch((error) => {
        bus.callHook("error", error);
      })
      .finally(() => {
        store.creating = false;
      });

    if (result?.data) {
      fill(result.data.checkoutCreate);
      return true;
    }

    return false;
  };

  const update = async (data: CheckoutUpdateInput): Promise<boolean> => {
    store.updating = true;
    const result = await client()
      .mutate<{ checkoutUpdate: Checkout }>({
        mutation: updateCheckoutMutation,
        variables: {
          token: checkout.token,
          data,
        },
      })
      .catch((error) => {
        bus.callHook("error", error);
      })
      .finally(() => {
        store.updating = false;
      });

    if (result?.data) {
      fill(result.data.checkoutUpdate);
      return true;
    }

    return false;
  };

  const setContact = async (
    data: Pick<
      CheckoutUpdateInput,
      "first_name" | "last_name" | "email" | "phone"
    >
  ) => update(pick(data, ["first_name", "last_name", "email", "phone"]));

  const setShippingAddress = async (address: AddressInput) =>
    update({
      shipping_address: {
        set: address,
      },
    });

  const setBillingAddress = async (address: AddressInput) =>
    update({
      billing_address: {
        set: address,
      },
    });

  const useShippingAddressAsBilling = async () =>
    update({
      billing_address: {
        use_shipping: true,
      },
    });

  const updateItemQuantity = async (id: string | number, quantity: number) =>
    update({
      lines: {
        update: [{ id: String(id), quantity }],
      },
    });

  const applyPromocode = async (code: string) =>
    update({
      discount: {
        set: [code],
      },
    });

  const removePromocode = async () =>
    update({
      discount: {
        clear: true,
      },
    });

  const selectShipping = async (line: string): Promise<boolean> => {
    store.updating = true;
    const result = await client()
      .mutate<{ checkoutSelectShipping: Checkout }>({
        mutation: checkoutSelectShippingMutation,
        variables: {
          token: checkout.token,
          line,
        },
      })
      .catch((error) => {
        bus.callHook("error", error);
      })
      .finally(() => {
        store.updating = false;
      });

    if (result?.data) {
      fill(result.data.checkoutSelectShipping);
      return true;
    }

    return false;
  };

  const complete = async (data?: CheckoutCompleteInput): Promise<boolean> => {
    store.updating = true;
    const result = await client()
      .mutate<{ checkoutComplete: Checkout }>({
        mutation: checkoutCompleteMutation,
        variables: {
          token: checkout.token,
          data,
        },
      })
      .catch((error) => {
        bus.callHook("error", error);
      })
      .finally(() => {
        store.updating = false;
      });

    if (result?.data) {
      fill(result.data.checkoutComplete);
      return true;
    }

    return false;
  };

  const getShippingMethods = async () => {
    store.fetching = true;
    const result = await client()
      .query<{ checkoutShippingMethods: ShippingMethod[] }>({
        query: checkoutShippingMethodsQuery,
        variables: {
          token: checkout.token,
        },
      })
      .catch((error) => {
        bus.callHook("error", error);
      })
      .finally(() => {
        store.fetching = false;
      });

    if (result) {
      checkout.shippingMethods = result.data.checkoutShippingMethods;
    }
  };

  if (token) {
    load(token);
  }

  return {
    store: readonly(store),
    data: readonly(checkout),
    load,
    create,
    update,
    setContact,
    setShippingAddress,
    setBillingAddress,
    useShippingAddressAsBilling,
    updateItemQuantity,
    applyPromocode,
    removePromocode,
    selectShipping,
    complete,
    getShippingMethods,
    onError,
  };
};
