<template>
  <v-breadcrumbs :items="crumbs">
    <template #item="{ item }">
      <a
        v-if="item.last"
        aria-current="page"
        class="v-breadcrumbs__item v-breadcrumbs__item--disabled"
      >
        <slot :name="item.name">{{ current || item.text }}</slot>
      </a>
      <a
        v-else-if="item.disabled"
        class="v-breadcrumbs__item v-breadcrumbs__item--disabled"
      >
        <slot :name="item.name">{{ item.text }}</slot>
      </a>
      <router-link v-else :to="item.to">
        <slot :name="item.name">{{ item.text }}</slot>
      </router-link>
    </template>
  </v-breadcrumbs>
</template>

<script>
import { upperFirst, capitalize } from "lodash";

export default {
  name: "Breadcrumbs",
  props: {
    current: { type: String, default: "" },
  },
  computed: {
    crumbs() {
      const paths = this.$route.path
        .substring(1)
        .replace(/\/$/, "") // trailing slashes
        .split("/");

      return paths.map((name, i) => {
        const p = paths.slice(0, ++i);
        const { name: n, params, query } = this.$router.match(p.join("/"));
        const last = i >= paths.length;

        return {
          text: i < paths.length ? upperFirst(name) : capitalize(name),
          name,
          last,
          disabled: n === null || last,
          to: n ? { name: n, params, query } : { name: p.join("-") },
        };
      });
    },
  },
};
</script>
