import type { Plugin } from "vue";
import VCountrySelect from "./VCountrySelect";
import VRegionSelect from "./VRegionSelect";

const plugin: Plugin = {
  install: function (app) {
    app.component("VCountrySelect", VCountrySelect);
    app.component("VRegionSelect", VRegionSelect);
  },
};

export default plugin;
export { VCountrySelect, VRegionSelect };
