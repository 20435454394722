import { defineComponent, h, type VNode, Teleport } from "vue";
import { VSheet, VMessages } from "vuetify/components";
import { omit, pick, upperFirst } from "lodash";
import VDynamicForm, { props, emits } from "../VDynamicForm/VDynamicForm";
import "./VInputGroup.scss";

const dynamicFormProps = Object.keys(props);
const dynamicFormEvents = emits.map((event) => "on" + upperFirst(event));

export default defineComponent({
  name: "VInputGroup",
  props: {
    ...VSheet.props,
    ...props,
    formTag: props.tag,
    tag: { typ: String, default: "div" },
    hideErrors: { type: Boolean, default: false },
    insetDetails: { type: Boolean, default: false },
    displaySingleErrors: { type: Boolean, default: false },
  },
  data: () => ({
    detailsPort: undefined as Element | undefined,
  }),
  methods: {
    createErrorDetails(errors: string[]): VNode {
      const errorMessages =
        this.displaySingleErrors && errors.length ? errors[0] : errors;

      return h(
        "div",
        {
          class: "v-input__details",
          style: {
            "padding-inline-start": "16px",
            "padding-inline-end": "16px",
            color: "rgb(var(--v-theme-error))",
            "--v-medium-emphasis-opacity": "1",
            "--v-field-border-opacity": "0.38",
          },
        },
        h(VMessages as any, {
          active: !!errorMessages.length,
          messages: errorMessages,
        })
      );
    },
    createDynamicForm() {
      const slots: any = Object.assign({}, this.$slots);

      if (!this.hideErrors) {
        slots.after = (props: any) => {
          const nodes: any[] = [];
          const errorDetails = this.createErrorDetails(
            Object.values(props.errors)
          );

          if (this.insetDetails) {
            nodes.push(errorDetails);
          } else if (this.detailsPort) {
            const teleport = h(
              Teleport,
              { to: this.detailsPort },
              errorDetails
            );
            nodes.push(teleport);
          }

          const afterSlot = this.$slots.after;
          if (afterSlot) {
            nodes.push(afterSlot(props));
          }
          return nodes;
        };
      }

      return h(
        VDynamicForm as any,
        {
          ...pick(this.$props, dynamicFormProps),
          ...pick(this.$attrs, dynamicFormEvents),
          tag: this.formTag,
          defaultProps: {
            ...this.defaultProps,
            variant: "outlined",
            singleLine: true,
            hideDetails: true,
          },
        },
        slots
      );
    },
  },
  render() {
    const sheet = h(
      VSheet,
      {
        ...omit(this.$props, [
          ...dynamicFormProps,
          "hideErrors",
          "displaySingleErrors",
          "formTag",
        ]),
        ...omit(this.$attrs, dynamicFormEvents),
        tag: this.tag,
        class: {
          "v-input-group": true,
          "v-input-group--inset-details": this.insetDetails,
        },
        style: {
          "--v-field-border-radius": "10px",
        },
      },
      this.createDynamicForm
    );

    if (this.insetDetails || this.hideErrors) {
      return sheet;
    }

    const detailsPort = h("div", {
      ref: (element) => (this.detailsPort = element as Element),
    });

    return [sheet, detailsPort];
  },
});
