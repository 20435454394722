import { isString } from "lodash";
import { defineComponent, type PropType, h, type DefineComponent } from "vue";
import type { GlobalComponents } from "vue";

type GlobalComponent = keyof GlobalComponents;

type Parameter<T> = T extends (...args: any) => any ? Parameters<T> : never;

type Props<T extends keyof GlobalComponents> = Parameter<
  GlobalComponents[T]["setup"]
>[0];

export interface LocalComponent<
  P extends any = any,
  C extends GlobalComponent | DefineComponent<P, {}, any> =
    | GlobalComponent
    | DefineComponent<P, {}, any>
> {
  is: C;
  props: C extends GlobalComponent ? Props<C> : P;
}

declare module "blocks" {
  export interface ComponentBlocks {
    "local-component-block": LocalComponent;
  }
}

export default defineComponent({
  name: "LocalComponentBlock",
  props: {
    content: {
      type: Object as PropType<LocalComponent>,
      required: true,
    },
  },
  render() {
    const Component = isString(this.content.is)
      ? resolveComponent(this.content.is)
      : this.content.is;

    return h(Component, {
      ...this.content.props,
    });
  },
});
