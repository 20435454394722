import type { PeriodIntervalUnit } from "~~/services/api-data/types";
import type { BaseModel } from "./model";

export interface PeriodInterval extends BaseModel {
  count: number;
  unit: PeriodIntervalUnit;
}

export enum PlatformAudience {
  ALL = "ALL",
  SELLERS = "SELLERS",
  BUYERS = "BUYERS",
  USERS = "USERS",
}
