import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
  type HttpOptions,
} from "@apollo/client/core";
import { destr } from "destr";
import { defu } from "defu";
import type { NuxtApp } from "nuxt/app";
import type { PublicRuntimeConfig } from "nuxt/schema";

export type SetClientOptions = {
  key: string;
  symbol: symbol;
  client: ApolloClient<any>;
};

type FnOptions = {
  nuxtApp: NuxtApp;
  config: PublicRuntimeConfig;
};

type Options = FnOptions & {
  errorLink: ApolloLink;
  setClient: (o: SetClientOptions) => void;
};

type ClientOptions = {
  key: string;
  symbol: symbol;
  httpOptions: HttpOptions;
  links?: ApolloLink[];
};

export default (fn: { (o: FnOptions): ClientOptions }) => {
  return ({ errorLink, nuxtApp, config, setClient }: Options) => {
    const fnOptions = fn({ nuxtApp, config });
    const httpOptions_ = defu(fnOptions.httpOptions, {
      uri: config.server.graph,
      credentials: config.server.credentials,
    });

    const httpLink = createHttpLink(httpOptions_);
    const cache = new InMemoryCache();

    const cacheKey = `_apollo:${fnOptions.key}`;

    nuxtApp.hook("app:rendered", () => {
      nuxtApp.payload.data[cacheKey] = cache.extract();
    });

    if (import.meta.client && nuxtApp.payload.data[cacheKey]) {
      cache.restore(destr(JSON.stringify(nuxtApp.payload.data[cacheKey])));
    }

    const apolloClient = new ApolloClient({
      cache,
      ...(import.meta.server ? { ssrMode: true } : { ssrForceFetchDelay: 100 }),
      link: ApolloLink.from([errorLink, ...(fnOptions.links || []), httpLink]),
    });

    nuxtApp.vueApp.provide(fnOptions.symbol, apolloClient);

    setClient({
      key: fnOptions.key,
      symbol: fnOptions.symbol,
      client: apolloClient,
    });

    return apolloClient;
  };
};
