import { useStorage } from "@vueuse/core";
import config from "#sales";
import { resolveCurrency } from "../utils";

export const useCurrency = () => {
  const value = useStorage("currency", config.defaultCurrency);
  const currency = computed(() => resolveCurrency(value.value));

  const set = (code: string) => (value.value = code);

  return {
    value,
    currency,
    set,
  };
};
