import { default as aboutu8Sb32ihwPMeta } from "/app/pages/about.vue?macro=true";
import { default as cart9SktLKPpbzMeta } from "/app/pages/cart.vue?macro=true";
import { default as contact_45uszp1HYnA7MsMeta } from "/app/pages/contact-us.vue?macro=true";
import { default as _91handle_93uNBqwAE65iMeta } from "/app/pages/events/[handle].vue?macro=true";
import { default as _91token_93xSkgyaGyjrMeta } from "/app/pages/events/discounts/[token].vue?macro=true";
import { default as indexWgIHVzns1AMeta } from "/app/pages/events/index.vue?macro=true";
import { default as _91slug_93hRTZEfJ7VsMeta } from "/app/pages/help/faqs/[slug].vue?macro=true";
import { default as index6Q5OCXlwaaMeta } from "/app/pages/help/faqs/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91handle_93AnD2NdC53CMeta } from "/app/pages/legal/[handle].vue?macro=true";
import { default as index8NBB3chSIOMeta } from "/app/pages/legal/index.vue?macro=true";
import { default as previewJ9VJgxiihgMeta } from "/app/pages/preview.vue?macro=true";
import { default as _91handle_93DwD6hl2jvvMeta } from "/app/pages/products/[handle].vue?macro=true";
import { default as index18IXo8oN8QMeta } from "/app/pages/products/index.vue?macro=true";
import { default as indexVy2tsFzaDdMeta } from "/app/pages/recipes/index.vue?macro=true";
import { default as _91handle_93ZIu3k3x7NuMeta } from "/app/pages/s/[handle].vue?macro=true";
import { default as _91service_93PxoFs3F9VcMeta } from "/app/pages/services/[provider]/[service].vue?macro=true";
import { default as indexsmqfyZb7bQMeta } from "/app/pages/services/[provider]/index.vue?macro=true";
import { default as bookingHn2rC6xysSMeta } from "/app/pages/services/booking.vue?macro=true";
import { default as indexmjydbmT8M8Meta } from "/app/pages/services/index.vue?macro=true";
import { default as rescheduleTFyBCNVlIMMeta } from "/app/pages/services/reschedule.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "cart",
    path: "/cart",
    meta: cart9SktLKPpbzMeta || {},
    component: () => import("/app/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/app/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "events-handle",
    path: "/events/:handle()",
    meta: _91handle_93uNBqwAE65iMeta || {},
    component: () => import("/app/pages/events/[handle].vue").then(m => m.default || m)
  },
  {
    name: "events-discounts-token",
    path: "/events/discounts/:token()",
    meta: _91token_93xSkgyaGyjrMeta || {},
    component: () => import("/app/pages/events/discounts/[token].vue").then(m => m.default || m)
  },
  {
    name: "events",
    path: "/events",
    meta: indexWgIHVzns1AMeta || {},
    component: () => import("/app/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "help-faqs-slug",
    path: "/help/faqs/:slug()",
    component: () => import("/app/pages/help/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "help-faqs",
    path: "/help/faqs",
    component: () => import("/app/pages/help/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "legal-handle",
    path: "/legal/:handle()",
    meta: _91handle_93AnD2NdC53CMeta || {},
    component: () => import("/app/pages/legal/[handle].vue").then(m => m.default || m)
  },
  {
    name: "legal",
    path: "/legal",
    component: () => import("/app/pages/legal/index.vue").then(m => m.default || m)
  },
  {
    name: "preview",
    path: "/preview",
    meta: previewJ9VJgxiihgMeta || {},
    component: () => import("/app/pages/preview.vue").then(m => m.default || m)
  },
  {
    name: "products-handle",
    path: "/products/:handle()",
    meta: _91handle_93DwD6hl2jvvMeta || {},
    component: () => import("/app/pages/products/[handle].vue").then(m => m.default || m)
  },
  {
    name: "products",
    path: "/products",
    meta: index18IXo8oN8QMeta || {},
    component: () => import("/app/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "recipes",
    path: "/recipes",
    meta: indexVy2tsFzaDdMeta || {},
    component: () => import("/app/pages/recipes/index.vue").then(m => m.default || m)
  },
  {
    name: "s-handle",
    path: "/s/:handle()",
    meta: _91handle_93ZIu3k3x7NuMeta || {},
    component: () => import("/app/pages/s/[handle].vue").then(m => m.default || m)
  },
  {
    name: "services-provider-service",
    path: "/services/:provider()/:service()",
    meta: _91service_93PxoFs3F9VcMeta || {},
    component: () => import("/app/pages/services/[provider]/[service].vue").then(m => m.default || m)
  },
  {
    name: "services-provider",
    path: "/services/:provider()",
    meta: indexsmqfyZb7bQMeta || {},
    component: () => import("/app/pages/services/[provider]/index.vue").then(m => m.default || m)
  },
  {
    name: "services-booking",
    path: "/services/booking",
    meta: bookingHn2rC6xysSMeta || {},
    component: () => import("/app/pages/services/booking.vue").then(m => m.default || m)
  },
  {
    name: "services",
    path: "/services",
    meta: indexmjydbmT8M8Meta || {},
    component: () => import("/app/pages/services/index.vue").then(m => m.default || m)
  },
  {
    name: "services-reschedule",
    path: "/services/reschedule",
    component: () => import("/app/pages/services/reschedule.vue").then(m => m.default || m)
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/faq",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/faqs",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/contact",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/gamesnight",
    component: component_45stubnO9MU04yTU
  }
]