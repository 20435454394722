import { PlatformAudience } from "./models/common";
import type { ThemeTypeOptions } from "./stores/theme";
import type { Currency } from "./types/commerce";

export default defineAppConfig({
  app: {
    name: "Marketplace",
    description:
      "Buy African products online. Find local events, services and things you’ll love. Support small businesses.",
    url: "orie.market",
    img: "/static/icon.png",
  },
  commerce: {
    defaultCurrency: { code: "AUD", symbol: "$" } as Currency,
    audient: PlatformAudience.BUYERS,
  },
  theme: {
    theme: "light" as ThemeTypeOptions,
    rtl: false,
  },
});
