import { defineComponent, h, type VNode, type PropType, type Slot } from "vue";
import { Form } from "vee-validate";
import { VCol, VRow } from "vuetify/components";
import { debounce, get, set } from "lodash";
import VDynamicFormField from "./VDynamicFormField";
import { castValue, parseFieldsInput, isEvent } from "../../utils";
import type { FieldInputs } from "../../types";

export const props = {
  modelValue: { type: Object },
  loading: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  defaultProps: {
    type: Object as PropType<any>,
    default: () => ({}),
  },
  defaults: { type: Object as PropType<any>, default: () => ({}) },
  inputs: { type: [Object, Array] as PropType<FieldInputs>, required: true },
  tag: { typ: String, default: "v-form" },
  nestedFields: { type: Boolean, default: false },
  validateOnBlur: { type: Boolean, default: true },
  validateOnChange: { type: Boolean, default: true },
  validateOnInput: { type: Boolean, default: false },
  validateOnModelUpdate: { type: Boolean, default: true },
  validateOnMount: { type: Boolean, default: false },
  watchExternalChanges: { type: Boolean, default: true },
};

export const emits = [
  "update:modelValue",
  "update:valid",
  "update:dirty",
  "update:errors",
  "submit",
];

type FieldComponent = InstanceType<typeof VDynamicFormField>;

export default defineComponent({
  name: "VDynamicForm",
  props,
  emits,
  setup(props, context) {
    const propRefs = toRefs(props);
    const updatingValue = ref(false);
    const refs: Record<string, FieldComponent> = {};

    const input = computed({
      get(): any {
        return (
          propRefs.modelValue.value ||
          Object.assign({}, propRefs.defaults.value)
        );
      },
      set(value: any) {
        context.emit("update:modelValue", value);
      },
    });

    const fields = computed(() =>
      parseFieldsInput(propRefs.inputs.value!, propRefs.defaultProps.value, {
        loading: propRefs.loading.value,
        disabled: propRefs.disabled.value,
        readonly: propRefs.readonly.value,
        validateOnBlur: propRefs.validateOnBlur.value,
        validateOnChange: propRefs.validateOnChange.value,
        validateOnInput: propRefs.validateOnInput.value,
        validateOnModelUpdate: propRefs.validateOnModelUpdate.value,
        validateOnMount: propRefs.validateOnMount.value,
      })
    );

    const endUpdatingValue: { (): unknown } = debounce(() => {
      updatingValue.value = false;
    }, 300);

    const getInputValue = (key: string | string[]): any => {
      if (propRefs.nestedFields.value) {
        return get(input.value, key);
      }
      return input.value[String(key)];
    };

    const setInputValue = (key: string | string[], value: any) => {
      if (propRefs.watchExternalChanges.value) {
        updatingValue.value = true;
      }

      const _key = String(key);
      if (isEvent(value)) {
        value = get(value.target, "value");
      }

      value = castValue(value, fields.value.casts[_key]);

      if (propRefs.nestedFields.value) {
        set(input.value, key, value);
      } else {
        input.value[_key] = value;
      }

      if (propRefs.watchExternalChanges.value) {
        nextTick(endUpdatingValue);
      }
    };

    const applyExternalChanges = (value: any) => {
      if (value) {
        Object.entries(value).forEach(([key, value]) => {
          const ref = refs[key];
          if (ref) {
            ref.handleChange(value);
          }
        });
      }
    };

    if (propRefs.watchExternalChanges.value) {
      watch(
        propRefs.modelValue,
        (value: any) => {
          if (!updatingValue.value) {
            applyExternalChanges(value);
          }
        },
        { deep: true }
      );

      onMounted(() =>
        nextTick(() => applyExternalChanges(propRefs.modelValue.value))
      );
    }

    const setRef = (key: string | string[], ref: FieldComponent) =>
      set(refs, String(key), ref);

    return {
      fields,
      getInputValue,
      setInputValue,
      setRef,
    };
  },
  methods: {
    getFields(slots: { readonly [x: string]: Slot | undefined }): VNode[] {
      const components: VNode[] = [];
      const lines = Object.entries(this.fields.lines).filter(
        ([_, inputs]) => !!inputs.filter((input) => !input.hidden).length
      );

      lines.forEach(([line, inputs], index) => {
        const items = inputs
          .filter((input) => !input.hidden)
          .map((input, inputIndex) => {
            const node = h(
              VDynamicFormField as any,
              {
                name: input.name,
                options: input,
                posTop: index == 0,
                posBottom: index == lines.length - 1,
                posLeft: inputIndex == 0,
                posRight: inputIndex == inputs.length - 1,
                value: this.getInputValue(input.key),
                onInput: (value: any) => this.setInputValue(input.key, value),
                ref: (ref: any) => this.setRef(input.key, ref),
              },
              slots
            );

            return {
              input,
              node,
            };
          });

        const mapItemsToCol = () =>
          items.map((item) =>
            h(
              VCol,
              {
                ...item.input.col,
              },
              () => item.node
            )
          );

        if (items.length > 1) {
          components.push(h(VRow, { noGutters: true }, mapItemsToCol));
        } else {
          components.push(...items.map((item) => item.node));
        }
      });

      return components;
    },
  },
  render() {
    return h(
      Form as any,
      {
        class: "v-dynamic-form",
        as: this.tag,
        validationSchema: this.fields.schema,
        validateOnBlur: this.validateOnBlur,
        validateOnChange: this.validateOnChange,
        validateOnInput: this.validateOnInput,
        validateOnModelUpdate: this.validateOnModelUpdate,
        validateOnMount: this.validateOnMount,
        initialValues: this.defaults,
      },
      // @ts-ignore
      (props) => {
        this.$emit("update:valid", props.meta.valid);
        this.$emit("update:dirty", props.meta.dirty);
        this.$emit("update:errors", props.errors);

        const { before, after, ...slots } = this.$slots;
        const nodes = this.getFields(slots);
        if (before) {
          // @ts-ignore
          nodes.unshift(before(props));
        }
        if (after) {
          // @ts-ignore
          nodes.push(after(props));
        }
        return nodes;
      }
    );
  },
});
