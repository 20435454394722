import {
  type Component,
  defineComponent,
  type PropType,
  resolveDirective,
} from "vue";
import LayoutStandard from "./ServiceProfileCard.vue";
import LayoutImage from "./ServiceProfileCardImage.vue";
import type {
  ServiceProfileCardSource,
  ServiceProfileCardImageSource,
} from "./types";

type Layouts = {
  standard: ServiceProfileCardSource;
  image: ServiceProfileCardImageSource;
};

export type ServiceProfileContentLayout = keyof Layouts;
export type ServiceProfileSourceType<L extends ServiceProfileContentLayout> =
  Layouts[L];

const layouts: { [K in ServiceProfileContentLayout]: Component } = {
  standard: LayoutStandard,
  image: LayoutImage,
};

export interface ServiceProfileContent<
  T extends ServiceProfileContentLayout = ServiceProfileContentLayout
> {
  layout?: T;
  source: string | ServiceProfileSourceType<T>;
}

declare module "blocks" {
  export interface ComponentBlocks {
    "service-profile-block": ServiceProfileContent;
  }
}

export default defineComponent({
  name: "ServiceProfileBlock",
  props: {
    content: {
      type: Object as PropType<ServiceProfileContent>,
      required: true,
    },
  },
  setup(props) {
    const component = computed(
      () => layouts[props.content.layout || "standard"]
    );
    return {
      component,
    };
  },
  render() {
    const node = h(this.component, {
      source: this.content.source,
    });

    const editable = resolveDirective("editable");

    return withDirectives(node, [[editable, this.content]]);
  },
});
