import {
  type Component,
  defineComponent,
  type PropType,
  resolveDirective,
} from "vue";
import LayoutStandard from "./RecipeCard.vue";
import LayoutImage from "./RecipeCardImage.vue";
import LayoutPoppedImage from "./RecipeCardPoppedImage.vue";
import type { RecipeCardSource, RecipeCardImageSource } from "./types";

type Layouts = {
  standard: RecipeCardSource;
  image: RecipeCardImageSource;
  "popped-image": RecipeCardSource;
};

export type RecipeContentLayout = keyof Layouts;
export type SourceType<L extends RecipeContentLayout> = Layouts[L];

const layouts: { [K in RecipeContentLayout]: Component } = {
  standard: LayoutStandard,
  image: LayoutImage,
  "popped-image": LayoutPoppedImage,
};

export interface RecipeContent<
  T extends RecipeContentLayout = RecipeContentLayout
> {
  layout?: T;
  source: string | SourceType<T>;
}

declare module "blocks" {
  export interface ComponentBlocks {
    "recipe-block": RecipeContent;
  }
}

export default defineComponent({
  name: "RecipeBlock",
  props: {
    content: {
      type: Object as PropType<RecipeContent>,
      required: true,
    },
  },
  setup(props) {
    const component = computed(
      () => layouts[props.content.layout || "standard"]
    );
    return {
      component,
    };
  },
  render() {
    const node = h(this.component, {
      source: this.content.source,
    });

    const editable = resolveDirective("editable");

    return withDirectives(node, [[editable, this.content]]);
  },
});
