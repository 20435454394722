import { useApolloClient } from "@vue/apollo-composable";
import type { Currency } from "../types";

// TODO: resolve currency
export const resolveCurrency = (_code: string): Currency => {
  return {
    symbol: "$",
    code: "AUD",
  };
};

export const client = () => {
  const { client } = useApolloClient(SalesApolloClient.toString());
  return client;
};

export const SalesApolloClient = Symbol("sales-apollo-client");
