import {
  type Component,
  defineComponent,
  type PropType,
  resolveDirective,
} from "vue";
import LayoutFeature from "./ProductCardFeature.vue";
import LayoutStandard from "./ProductCard.vue";
import LayoutImage from "./ProductCardImage.vue";
import type {
  ProductCardFeatureSource,
  ProductCardSource,
  ProductCardImageSource,
} from "./types";

type Layouts = {
  feature: ProductCardFeatureSource;
  standard: ProductCardSource;
  image: ProductCardImageSource;
};

export type ProductContentLayout = keyof Layouts;
export type ProductSourceType<L extends ProductContentLayout> = Layouts[L];

const layouts: { [K in ProductContentLayout]: Component } = {
  feature: LayoutFeature,
  standard: LayoutStandard,
  image: LayoutImage,
};

export interface ProductContent<
  T extends ProductContentLayout = ProductContentLayout
> {
  layout?: T;
  source: string | ProductSourceType<T>;
}

declare module "blocks" {
  export interface ComponentBlocks {
    "product-block": ProductContent;
  }
}

export default defineComponent({
  name: "ProductBlock",
  props: {
    content: {
      type: Object as PropType<ProductContent>,
      required: true,
    },
  },
  setup(props) {
    const component = computed(
      () => layouts[props.content.layout || "standard"]
    );
    return {
      component,
    };
  },
  render() {
    const node = h(this.component, {
      source: this.content.source,
    });

    const editable = resolveDirective("editable");

    return withDirectives(node, [[editable, this.content]]);
  },
});
