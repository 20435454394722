import gql from "graphql-tag";

const itemQueryFields = `
  id
  gid
  name
  quantity
  total
  image {
    id
    fqfn
    type
    src
    thumb
    alt
  }
  pricing {
    model
    tiers { max unit_amount flat_amount }
    unit_amount
    units
    data { measurementType}
  }
`;

const cartQueryFields = `
  id
  subtotal
  total
  currency { symbol code }
  items {${itemQueryFields}}
`;

export const addToCartMutation = gql`
  mutation ($data: CartItemAddInput!, $cart: ID) {
    cartAddItem(data: $data, cart: $cart) {
      item {${itemQueryFields}}
      cart {${cartQueryFields}}
    }
  }
`;

export const updateCartItemMutation = gql`
  mutation ($data: CartItemUpdateInput!, $where: CartItemUniqueWhereInput!, $cart: ID) {
    cartUpdateItem(data: $data, where: $where, cart: $cart) {
      item {${itemQueryFields}}
      cart {${cartQueryFields}}
    }
  }
`;

export const removeCartItemMutation = gql`
  mutation ($where: CartItemUniqueWhereInput!, $cart: ID) {
    removeFromCart(where: $where, cart: $cart) {
      ${cartQueryFields}
    }
  }
`;

export const clearCartMutation = gql`
  mutation ($upsert: Boolean, $cart: ID) {
    cartClear(upsert: $upsert, cart: $cart)
  }
`;

export const checkoutCartMutation = gql`
  mutation ($cart: ID, $data: CartCheckoutInput) {
    cartCheckout(cart: $cart, data: $data) {
      status
      checkoutUrl
      errors {
        codes
        messages
      }
    }
  }
`;

export const cartQuery = gql`
  query ($upsert: Boolean, $cart: ID){
    cart (upsert: $upsert, cart: $cart){${cartQueryFields}}
  }
`;
