import { defineComponent, type PropType, type VNode } from "vue";

export interface Text {
  tag?: string;
  title?: string;
  subtitle?: string;
  body: string;
  maxWidth?: string | number;
  style?: Record<string, any>;
}

declare module "blocks" {
  export interface ComponentBlocks {
    "text-block": Text;
  }
}

export default defineComponent({
  name: "TextBlock",
  props: {
    content: { type: Object as PropType<Text>, required: true },
  },
  render() {
    const content: VNode[] = [];
    if (this.content.title) {
      content.push(h("h1", { class: "mb-2" }, this.content.title));
    }
    if (this.content.subtitle) {
      content.push(h("h3", this.content.subtitle));
    }
    content.push(
      h(
        "div",
        {
          style: {
            "max-width": convertToUnit(this.content.maxWidth || 800, "px"),
          },
        },
        h("p", this.content.body)
      )
    );
    return h(this.content.tag || "div", { style: this.content.style }, content);
  },
});
