import type { ComponentBlocks } from "blocks";
import { defineComponent, type PropType } from "vue";
import type { ServiceProfile } from "~~/models/service";
import type { ServiceProfileQueryFilter } from "~~/types/models";
import Grid, { type GridLayout } from "../grid/Grid.block";
import Slider, { type SliderContent } from "../slider";
import type {
  ServiceProfileContentLayout,
  ServiceProfileSourceType,
} from "./ServiceProfile.block";

export interface ServiceProfilesContent {
  layout?: GridLayout | "slider";
  layoutOptions?: ComponentBlocks["grid-block"]["layoutOptions"];
  itemLayout?: ServiceProfileContentLayout;
  slider?: Omit<SliderContent, "columns">;
  data: string[] | ServiceProfile[] | ServiceProfileQueryFilter;
}

declare module "blocks" {
  export interface ComponentBlocks {
    "service-profiles-block": ServiceProfilesContent;
  }
}

export default defineComponent({
  name: "ServiceProfilesBlock",
  props: {
    content: {
      type: Object as PropType<ServiceProfilesContent>,
      required: true,
    },
  },
  setup(props) {
    let data: Ref<(string | ServiceProfile)[]>;

    if (Array.isArray(props.content.data)) {
      data = ref(props.content.data);
    } else {
      // fetch data
      const apiData = useServiceProfiles({
        filter: props.content.data,
      });
      data = apiData.services;
    }

    return {
      data,
    };
  },
  render() {
    const columns = this.data.map((source: ServiceProfileSourceType<any>) =>
      defineComponentBlock({
        component: "event-block",
        layout: this.content.itemLayout,
        source,
      })
    );

    if (this.content.layout === "slider") {
      return h(Slider, {
        content: {
          ...this.content.slider,
          columns,
        },
      });
    }

    return h(Grid, {
      content: {
        layout: this.content.layout,
        layoutOptions: this.content.layoutOptions,
        columns,
      },
    });
  },
});
