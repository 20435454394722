import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_KlVwwuJRCL from "/app/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import plugin_tyiuIr3sFJ from "/app/modules/sales/src/runtime/plugin.ts";
import plugin_t2GMTTFnMT from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_iGLjBbor9q from "/app/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import plugin_w2VlvAEcpa from "/app/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_tbFNToZNim from "/app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import pwa_icons_Y6agwlnXZq from "/app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_Eorgdr12jA from "/app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import unocss_MzCDxu9LMj from "/app/.nuxt/unocss.mjs";
import index_JcX2pf9efO from "/app/plugins/apollo/index.ts";
import mobile_vh_client_oCKXSRiEoX from "/app/plugins/mobile-vh.client.ts";
import notification_h17XTKlCK5 from "/app/plugins/notification.ts";
import packages_340RWGHlC8 from "/app/plugins/packages.ts";
import posthog_client_fUucxKWhZE from "/app/plugins/posthog.client.ts";
import vee_validate_KcKlKmvCrJ from "/app/plugins/vee-validate.ts";
import vuetify_7h9QAQEssH from "/app/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_KlVwwuJRCL,
  plugin_tyiuIr3sFJ,
  plugin_t2GMTTFnMT,
  plugin_iGLjBbor9q,
  plugin_w2VlvAEcpa,
  plugin_tbFNToZNim,
  pwa_icons_Y6agwlnXZq,
  pwa_client_Eorgdr12jA,
  unocss_MzCDxu9LMj,
  index_JcX2pf9efO,
  mobile_vh_client_oCKXSRiEoX,
  notification_h17XTKlCK5,
  packages_340RWGHlC8,
  posthog_client_fUucxKWhZE,
  vee_validate_KcKlKmvCrJ,
  vuetify_7h9QAQEssH
]