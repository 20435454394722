import { defineComponent, type PropType, h, type Component } from "vue";
import { Field } from "vee-validate";
import type { FieldInput } from "../../types";

export default defineComponent({
  name: "VDynamicFormField",
  props: {
    value: {},
    name: { type: String, required: true },
    options: { type: Object as PropType<FieldInput>, required: true },
    posTop: { type: Boolean, default: false },
    posBottom: { type: Boolean, default: false },
    posLeft: { type: Boolean, default: false },
    posRight: { type: Boolean, default: false },
  },
  setup() {
    const formField = ref<InstanceType<typeof Field>>();

    const handleChange = (value: any) => {
      if (formField.value) {
        formField.value.handleChange(value);
      }
    };

    return {
      handleChange,
      formField,
    };
  },
  render() {
    const handleChange = (value: any) => this.$emit("input", value);

    const slot = this.$slots[this.name];
    if (slot) {
      return slot({
        modelValue: this.value,
        input: this.options,
        handleChange,
        pos: {
          top: this.posTop,
          bottom: this.posBottom,
          left: this.posLeft,
          right: this.posRight,
        },
      });
    }

    return h(
      Field as any,
      {
        name: this.name,
        rules: this.options.rules,
        validateOnBlur: this.options.validateOnBlur,
        validateOnChange: this.options.validateOnChange,
        validateOnInput: this.options.validateOnInput,
        validateOnModelUpdate: this.options.validateOnModelUpdate,
        validateOnMount: this.options.validateOnMount,
        ref: (el: any) => (this.formField = el),
      },
      // @ts-ignore
      ({ field, errors, meta, handleChange: fieldHandleChange }) => {
        const modelValue = field.value || this.value;

        const slot = this.$slots[this.options.key + ":field"];
        if (slot) {
          return slot({
            field,
            input: this.options,
            errors,
            modelValue,
            handleChange,
            meta,
            pos: {
              top: this.posTop,
              bottom: this.posBottom,
              left: this.posLeft,
              right: this.posRight,
            },
          });
        }

        return h(this.options.component as string | Component, {
          ...field,
          ...this.options.props,
          value: modelValue,
          modelValue,
          class: {
            "v-dynamic-form-field": true,
            "v-dynamic-form-field--top": this.posTop,
            "v-dynamic-form-field--bottom": this.posBottom,
            "v-dynamic-form-field--left": this.posLeft,
            "v-dynamic-form-field--right": this.posRight,
          },
          errorMessages: errors,
          label: this.options.label,
          loading: this.options.loading,
          disabled: this.options.disabled,
          readonly: this.options.readonly,
          "onUpdate:modelValue": (value: any) => {
            handleChange(value);
            fieldHandleChange(value);
          },
        });
      }
    );
  },
});
