import defaultClientCreator from "./base";

export const ContentApolloClient = Symbol("content-apollo-client");

export default defaultClientCreator(({ config }) => ({
  key: "content",
  httpOptions: {
    headers: {
      "X-Access": "orie-content",
      // @ts-expect-error this should be a valid header
      "X-ClientAccessToken": config.contentApiAccessToken,
    },
  },
}));
